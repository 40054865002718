// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-i-18-n-redirect-jsx": () => import("/buddy/gatsby-starter-intl/src/i18n/redirect.jsx" /* webpackChunkName: "component---src-i-18-n-redirect-jsx" */),
  "component---src-pages-404-jsx": () => import("/buddy/gatsby-starter-intl/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("/buddy/gatsby-starter-intl/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-page-2-jsx": () => import("/buddy/gatsby-starter-intl/src/pages/page-2.jsx" /* webpackChunkName: "component---src-pages-page-2-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/buddy/gatsby-starter-intl/.cache/data.json")

